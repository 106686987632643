<template>
	<div>
		<jf-layout>
			<template slot="head">
				<el-form class="form" :inline="true" :model="pageParam">
					<el-form-item label="">
						<el-select placeholder="审核状态" v-model="pageParam.checkStatus" size="small" clearable>
							<el-option label="待审核" :value="0"></el-option>
							<el-option label="审核通过" :value="1"></el-option>
							<el-option label="审核未通过" :value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="">
						<el-input placeholder="企业名称" maxLength="20" v-model="pageParam.enterpriseName" size="small" clearable>
						</el-input>
					</el-form-item>
					<el-form-item label="">
						<el-input placeholder="注册手机号" maxLength="11" size="small" v-model.number="pageParam.mobile" clearable></el-input>
					</el-form-item>
					<el-form-item label="">
						<el-date-picker placeholder="注册日期" v-model="pageParam.registerDate" size="small" clearable>
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button size="small" type="primary" icon="el-icon-search" @click="getList('reset')">查询
						</el-button>
						<el-button size="small" type="primary" icon="el-icon-search" v-if="isAuth('platform-admin:tenantapply:export')">导出
						</el-button>
					</el-form-item>
				</el-form>
			</template>
			<el-table slot="body" border :data="list">
				<el-table-column align="center" type="selection" width="50" />
				<el-table-column align="center" prop="name" label="姓名" />
				<el-table-column align="center" prop="mobile" label="手机号码" />
				<el-table-column align="center" prop="enterpriseName" label="企业名称"/>
				<el-table-column align="center" prop="createDate" label="申请日期"></el-table-column>
				<el-table-column align="center" prop="realName" label="审核人"></el-table-column>
				<el-table-column align="center" prop="checkTime" label="审核时间"></el-table-column>
				<el-table-column align="center" label="审核状态">
					<template slot-scope="{row}">
						<el-tag v-if="row.checkStatus==0">待审核</el-tag>
						<el-tag type="success" v-if="row.checkStatus==1">审核通过</el-tag>
						<el-tag type="warning" v-if="row.checkStatus==2">审核未通过</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="duty" label="职务" />
				<el-table-column align="center" label="操作">
					<template slot-scope="{row}">
						<el-button size="mini" v-if="row.checkStatus==0&&isAuth('platform-admin:tenantapply:check')" @click="openCheck(row)">审核</el-button>
						<el-button size="mini" v-if="isAuth('platform-admin:tenantapply:delete')"  icon="el-icon-delete" @click="delItem(row)" type="danger">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<jf-page slot="foot" :total="total" :page.sync="pageParam.page" :limit.sync="pageParam.limit" @change="getList()"/>
		</jf-layout>
		<el-dialog title="审核" :visible.sync="checkShow" width="30%" :before-close="closeCheck">
			<el-form ref="checkForm" label-width="80px" :model="checkParam">
				<el-form-item label="审核状态">
					<el-radio-group v-model="checkParam.state">
						<el-radio :label="1">审核通过</el-radio>
						<el-radio :label="0">审核不通过</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="审核备注" v-if="checkParam.state!=1">
					<el-input type="textarea" :rows="2" maxLength="100" placeholder="请输入内容" v-model="checkParam.remark">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeCheck">取 消</el-button>
				<el-button type="primary" @click="checkFun" :loading="btnLoading">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				checkShow: false,
				pageParam: {
					page: 1,
					limit: 10
				},
				list: [],
				total: 0,
				tableLoading: false,
				btnLoading: false,
				checkParam: {
					state: 1
				},
			};
		},
		created() {
			this.getList()
		},
		methods: {
			// 获取列表
			getList(reset) {
				let regPhone = /^1[3456789]{1}\d{9}$/;
				if(this.pageParam.mobile&&!regPhone.test(this.pageParam.mobile)){
					this.$message.warning("请输入正确的手机号进行搜索！")
					return
				}
				this.tableLoading = true;
				if (reset) {
					this.pageParam.page = 1
				}
				this.$get('/platform-admin/tenantapply/page', this.pageParam).then(res=>{
					this.tableLoading = false;
					if (res && res.code == 0) {
						this.list = res.data.list;
						this.total = res.data.total;
					}
				})
			},
			// 打开审核弹窗
			openCheck(row) {
				 this.checkParam.tenantApplyIdList=[row.id];
				this.checkShow = true
			},
			// 关闭审核弹窗
			closeCheck() {
				this.checkShow = false
			},
			// 删除
			delItem(row){
				this.$confirm("确认删除选择的数据", "提示", {
				  confirmButtonText: "确定",
				  cancelButtonText: "取消",
				  type: "warning",
				})
				  .then(async () => {
				    var res = await this.$del('/platform-admin/tenantapply',[row.id]);
				    if (res && res.code === 0) {
				      this.$message({
				        type: "success",
				        message: "删除成功!",
				      });
				      this.getList();
				    }
				  })
				  .catch(() => {});
			},
			// 审核
			async checkFun() {
				let url = "/platform-admin/tenantapply/checkPass"
				if (this.checkParam.state == 0) {
					url = "/platform-admin/tenantapply/checkFail"
					if (!this.checkParam.remark) {
						this.$message.warning("请输入审核备注！");
						return
					}
				}
				this.btnLoading = true;
				let res = await this.$put(url, this.checkParam);
				this.btnLoading = false;
				if (res && res.code == 0) {
					this.$message.success("审核提交成功！");
					this.getList()
					this.closeCheck()
				}
			}
		}
	};
</script>
